import React, { useRef, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { RightBox, BulletBox } from '../../pages/our-platform/module/styled'
import { useContentInProp } from '@/hooks/useContentInProp'
import { TweenMax } from 'gsap'
import { Left, MobileFooter } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import MobileBack from '@/components/MobileBack'

export const query = graphql`
  query cmsModule($id: String) {
    cmsModule(id: { eq: $id }) {
      id
      slug
      title
      intro
      body
    }
  }
`

const ModulesPage: React.FC = ({ data }) => {
  const { t } = useTranslation()
  const inProp = useContentInProp()
  const bulletBox = useRef(null)
  const rightBox = useRef(null)
  const isServer = typeof window === 'undefined'

  let isMobile = false

  if (!isServer) {
    if (document.documentElement.clientWidth <= 960) {
      isMobile = true
    }
  }

  useLayoutEffect(() => {
    const boxToTween = isMobile ? bulletBox.current : rightBox.current

    TweenMax.fromTo(
      boxToTween,
      2,
      {
        opacity: 0,
      },
      {
        delay: 1,
        ease: 'sine.out',
        opacity: 1,
      },
    )
  })

  return (
    <>
      <Left>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url="/our-platform/all-modules"
            text="back"
          />
        )}
      </Left>
      <Page
        content={
          <PageWrapper scrollable>
            <WithSlideShowCheck
              mobileContent={[
                <>
                  <Text
                    isMobile
                    title={data.cmsModule.title}
                    text={data.cmsModule.intro}
                    link={t('our-platform.module_cta')}
                    url="/our-platform/all-modules"
                  />
                  {inProp && (
                    <BulletBox
                      ref={bulletBox}
                      dangerouslySetInnerHTML={{
                        __html: data.cmsModule.body,
                      }}
                    />
                  )}
                </>,
              ]}
            >
              <Box row justifyContent="space-between">
                <Box col={0.43}>
                  <Text
                    title={data.cmsModule.title}
                    text={data.cmsModule.intro}
                    link={t('our-platform.module_cta')}
                    url="/our-platform/all-modules"
                  />
                </Box>
                {inProp && (
                  <RightBox
                    ref={rightBox}
                    col={0.5}
                    dangerouslySetInnerHTML={{ __html: data.cmsModule.body }}
                  />
                )}
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          alwaysShow
          variant="light"
          delay={0}
          time={1}
          url="/our-platform/all-modules"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default ModulesPage
