import styled, { Box } from '@xstyled/styled-components'

export const BulletBox = styled(Box)`
  position: relative;
  font-size: 16px;
  display: flex;
  justify-content: center;
  li {
    list-style: none;
    background: url('/images/bullet.png') 0 8px no-repeat;
    padding-left: 20px;
  }
`

export const RightBox = styled(BulletBox)`
  padding-left: 130px;
  border-left: 2px solid #515151;
`
